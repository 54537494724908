@import '../../../scss/Fonts.scss';
@import '../../../scss/Colors.scss';

.drafting-page__container {
  font-family: $mukta;
  height: calc(100vh - 60px);
}

.drafting-container {
  display: flex;
  overflow-y: hidden;
  height: calc(100% - 110px);
  overflow-x: hidden;
}

.document-tiles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  &__sectionTitle {
    margin-top: 20px;
    margin-left: 20px;
    color: $dark-grey;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid $lighter-grey;
  }
  &__attachmentsSectionTitle {
    margin-top: 20px;
    margin-left: 20px;
    color: $dark-grey;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid $lighter-grey;
  }
  &__documents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
  &__attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
}

.draftingAgreement__leftPanel {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // flex-basis: 300px;
  border-right: 1px solid $lighter-grey;
  height: 100%;
  max-height: calc(100vh - 110px);
  padding-top: 22px;
  padding-left: 45px;
  padding-right: 68px;
  background-color: #f4f3f2;
  min-width: 550px;
  max-width: 550px;
  // min-width: 436px;
  position: fixed;
  overflow-y: auto;
  &--hidden {
    left: -200px;
  }
  .lp-section {
    margin-top: 25px;
    // min-width: 436px;
    &__title {
      font-family: $mukta;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: $dark-grey;
      border-bottom: 1px solid $divider;
    }
  }
}

.draftingAgreement__rightArea {
  display: flex;
  width: 100%;
  margin-left: 540px;
}
