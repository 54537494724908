.paper
  width: 120px
  height: 150px
  background-image: url('../../images/paper.svg')
  background-position: center
  background-repeat: no-repeat

.papers
  display: flex
  align-items: center
  justify-content: center
  margin-top: 40px
  width: 114px
  height: 142px
  background-image: url('../../images/paper.svg')
  background-position: left
  background-repeat: no-repeat
  position: relative
  &::after, &::before
    width: 100%
    height: 100%
    content: ''
    display: block
    position: absolute
    background-image: url('../../images/paper.svg')
    background-position: left
    background-repeat: no-repeat
  &::after
    left: 20px
    top: -20px
    z-index: -1
  &::before
    z-index: -2
    left: 40px
    top: -40px

