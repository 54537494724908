
$sidebar-width: 245px
.wrapper
  display: flex
.sidebar
  flex-shrink: 0
  flex-grow: 0
  width: $sidebar-width
  padding-left: 10px
  \:global(.ck .ck-annotation__info)
    overflow: hidden
    flex-wrap: wrap

.editor
  width: 100%
  border: 1px solid var(--ck-color-base-border)
  border-radius: var(--ck-border-radius)

  /* This element is a flex container for easier rendering. */
  display: flex
  flex-flow: column nowrap

.toolbar
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2)

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border)

  /* Adjust the look of the toolbar inside the container. */

  .ck-toolbar
    border: 0
    border-radius: 0

/* Make the editable container look like the inside of a native word processor application. */

.container
  padding: calc(var(--ck-spacing-large))
  background: var(--ck-color-base-foreground)

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll
.container .editable

  border: 1px hsl(0, 0%, 82.7%) solid
  border-radius: var(--ck-border-radius)
  background: white

  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1)
  width: 21cm
  min-height: 29.7cm

  margin: 0 auto

.container :global(.ck.ck-editor__editable)

  width: 21cm
  min-height: 29.7cm

  box-sizing: border-box
  padding-top: var(--pactt-document-margin-top)
  padding-bottom: var(--pactt-document-margin-bottom)
  padding-left: var(--pactt-document-margin-left)
  padding-right: var(--pactt-document-margin-right)

  span[data-pactt-attribute-id]
    position: relative
    background-color: #66ff66
    display: inline-block

    &::selection
      display: none
    &::before
      display: none
      user-select: none
      padding: 5px
      background-color: #333
      color: #fff
      user-select: none
      font-family: sans-serif
      position: absolute
      border-radius: 5px
      line-height: 150%
      font-size: 16px
      content: attr(data-pactt-attribute-tooltip-text)
      transition: opacity 100ms
      z-index: 1

    &:hover
      &::before
        display: inline-block

    &:empty
      background-color: #ff6666
      &::after
        content: '...'

.toolbar :global(.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se)
  max-height: 400px
  overflow: scroll
